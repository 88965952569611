/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';
import { ChildrenType, MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';

import './Overlay.style';

/** @namespace Pwa/Component/Overlay/Component/OverlayComponent */
export class OverlayComponent extends SourceOverlay {
    static propTypes = {
        mix: MixType,
        id: PropTypes.string.isRequired,
        onVisible: PropTypes.func,
        onHide: PropTypes.func,
        activeOverlay: PropTypes.string.isRequired,
        areOtherOverlaysOpen: PropTypes.bool.isRequired,
        isStatic: PropTypes.bool,
        isRenderInPortal: PropTypes.bool,
        children: ChildrenType,
        device: DeviceType.isRequired,
        bypassMobile: PropTypes.bool,
        fullBlack: PropTypes.bool
    };

    static defaultProps = {
        mix: {},
        children: [],
        onVisible: () => { },
        isStatic: false,
        onHide: () => { },
        isRenderInPortal: true,
        bypassMobile: false,
        fullBlack: false
    };

    __construct(props) {
        super.__construct(props);

        this.fullBlackDiv = null;
    }

    componentDidMount() {
        this.updateFullBlack();
    }

    componentWillUnmount() {
        this.removeFullBlack();
    }

    updateFullBlack() {
        const { fullBlack } = this.props;

        if (!!fullBlack && !this.fullBlackDiv) {
            this.fullBlackDiv = document.createElement('div');
            this.fullBlackDiv.className = 'fullBlack';
            document.body.appendChild(this.fullBlackDiv);
        } else if (!fullBlack && this.fullBlackDiv) {
            this.removeFullBlack();
        }
    }

    removeFullBlack() {
        if (this.fullBlackDiv) {
            document.body.removeChild(this.fullBlackDiv);
            this.fullBlackDiv = null;
        }
    }

    renderInMobilePortal(content) {
        const {
            bypassMobile, isStatic, isRenderInPortal, device
        } = this.props;

        if (!bypassMobile && !isStatic && device.isMobile && isRenderInPortal) {
            return createPortal(content, document.body);
        }

        return content;
    }

    render() {
        const {
            children,
            mix,
            areOtherOverlaysOpen,
            isStatic,
            bypassMobile
        } = this.props;

        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <div
              block="Overlay"
              ref={ this.overlayRef }
              mods={ {
                  isVisible, isStatic, bypassMobile, isInstant: areOtherOverlaysOpen
              } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                    { children && children }
            </div>
        );
    }
}

export default OverlayComponent;

/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import McMenuDropdown from 'Component/McMenuDropdown';
import McMenuFullScreen from 'Component/McMenuFullScreen';
// import Link from 'Component/Link';
import { McMenuNodeType } from 'Type/McMenuType';

import './McMenuItem.style';

/** @namespace Pwa/Component/McMenuItem/Component/McMenuItemComponent */
export class McMenuItemComponent extends PureComponent {
    static propTypes = {
        data: McMenuNodeType.isRequired,
        isOpen: PropTypes.bool.isRequired,
        cms_block: PropTypes.string,
        onMenuOpen: PropTypes.func.isRequired,
        onMenuClose: PropTypes.func.isRequired
    };

    static defaultProps = {
        cms_block: undefined
    };

    renderMenuTrigger() {
        const {
            data: { name, path, is_offerte: isOfferte }, onMenuOpen, isOpen: isActive
        } = this.props;

        return (
            <div
              key={ path }
              block="McMenuItem"
              elem="Link"
              role="button"
              tabIndex="0"
              onClick={ () => onMenuOpen() }
            >
                    <span
                      block="McMenuItem"
                      elem="Text"
                      mods={ { isMainMenu: true, isActive, isOfferte } }
                    >
                        { name }
                    </span>
            </div>
        );
    }

    renderOldMenuContainer() {
        const { data, isOpen } = this.props;
        if (!isOpen) {
            return null;
        }

        if (data.cms_block.length > 0 && !data.is_offerte) {
            return (
                <McMenuFullScreen data={ data } />
            );
        }

        return (
            <McMenuDropdown data={ data } />
        );
    }

    renderMenuContainer() {
        const { data, isOpen } = this.props;
        if (!isOpen) {
            return null;
        }

        return (
            <McMenuFullScreen data={ data } />
        );
    }

    render() {
        const { onMenuClose, data: { path } } = this.props;
        return (
            <ClickOutside
              onClick={ onMenuClose }
              key={ path }
            >
                <li block="McMenuItem">
                        { this.renderMenuTrigger() }
                        { this.renderMenuContainer() }
                </li>
            </ClickOutside>

        );
    }
}

export default McMenuItemComponent;

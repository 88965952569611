/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';
import Link from '@scandipwa/scandipwa/src/component/Link';

import IfStore from 'Component/IfStore';
import {
    NoMatch as SourceNoMatch
} from 'SourceRoute/NoMatch/NoMatch.component';

import Image404ES from './Images/404_es.png';
import Image404 from './Images/404.png';

import './NoMatch.override.style';

/** @namespace Pwa/Route/NoMatch/Component/NoMatchComponent */
export class NoMatchComponent extends SourceNoMatch {
    componentDidMount() {
        super.componentDidMount();
        document.head.ariaHidden = '404';
    }

    componentWillUnmount() {
        document.head.removeAttribute('aria-hidden');
    }

    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >
                    <IfStore storeCode="default">
                    <img alt="404image" src={ Image404 } />

                    </IfStore>
                    <IfStore storeCode="spain">
                    <img alt="404image" src={ Image404ES } />

                    </IfStore>
                    <h1>
                    { __('Oops, sembra che tu abbia preso una strada diversa!') }
                    </h1>
                    <p block="NoMatch" elem="Subtitle">
                    { __('Non preoccuparti, torniamo insieme al percorso giusto.') }
                    </p>
                    <Link
                      to="/"
                      block="NoMatch"
                      elem="Button"
                      mix={ { block: 'Button' } }
                    >
                        { __('TORNA ALLA PAGINA INIZIALE') }
                    </Link>
                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatchComponent;

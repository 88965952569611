import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { NO_MATCH } from 'Component/Header/Header.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NoMatchContainer as SourceNoMatchContainer
} from 'SourceRoute/NoMatch/NoMatch.container';

/** @namespace Pwa/Route/NoMatch/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Route/NoMatch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Route/NoMatch/Container/NoMatchContainer */
export class NoMatchContainer extends SourceNoMatchContainer {
    updateHeaderState() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: NO_MATCH,
            title: __('Page not found'),
            isHiddenOnMobile: false
        });
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NoMatchContainer)
);

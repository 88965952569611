/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable fp/no-let */
import { showNotification } from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import { prepareQuery } from '@scandipwa/scandipwa/src/util/Query';
import { executeGet } from '@scandipwa/scandipwa/src/util/Request';
import axios from 'axios';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CollezioniQuery from 'Query/Collezioni.query';
import {
    DEFAULT_HEADER_STATE,
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Header/Header.container';
import { MENU_TAB } from 'SourceComponent/NavigationTabs/NavigationTabs.config';
import { CUSTOMER, ONE_MONTH_IN_SECONDS } from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    getCollections, getFlagConfigurator, getFlagConfiguratorChair, getFlagConfiguratorSofas
} from 'Store/McMenu/McMenu.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { COLLECTIONS_ALLOWED } from '../../route/Collezioni/Collezioni.config';
import Header from './Header.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export {
    DEFAULT_HEADER_STATE
};

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS
};

/** @namespace Pwa/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    storeCode: state.ConfigReducer.code
});

/** @namespace Pwa/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    logout: () => {
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.logout(false, dispatch)
        );
    },
    signInSso: () => {
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.signInSso(dispatch)
        );
    },
    getFlagConfigurator: (data) => dispatch(getFlagConfigurator(data)),
    getCollections: (collections) => dispatch(getCollections(collections)),
    getFlagConfiguratorChair: (data) => dispatch(getFlagConfiguratorChair(data)),
    getFlagConfiguratorSofas: (data) => dispatch(getFlagConfiguratorSofas(data))
});

/** @namespace Pwa/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends SourceHeaderContainer {
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const { flagBFConfigurator } = this.state;

        // Scroll to top when page is changed
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
        if ((window.location.pathname.includes('offerte/miglior-prezzo') || window.location.pathname.includes('offerte/sconto-iva') || window.location.pathname === '/black-friday' || window.location.pathname === '/')
            && flagBFConfigurator === '1') {
            document.body.classList.add('darkTheme');
        } else {
            document.body.classList.remove('darkTheme');
        }

        /** hide header on devices < 1919 * */
        const w = window;
        const isMobile = w.matchMedia('(max-width: 810px)').matches;
        if (w.matchMedia('(max-width: 1919px)').matches) {
            const header = document.querySelector('.Header');
            const navbar = document.querySelector('.Header-Nav');
            const navbarServices = (window.location.pathname.indexOf('/servizio-clienti') > -1
            || window.location.pathname.indexOf('/atencion-al-cliente') > -1)
                ? document.querySelector('.customer-service_menu') : null;
            let lastScrollTop = 0;
            const navbarHeight = header.offsetHeight;
            const originalHeaderPosition = navbar.offsetHeight;

            w.addEventListener('scroll', () => {
                const overlay = document.querySelector('.McMenuFullScreen-Container_isVisible');
                if (window.location.pathname !== '/menu' && !overlay) {
                    const st = w.scrollY;
                    // const swiperADO = document.querySelector('.ADOCategoryPageFilterTag.Box');

                    if (isMobile) {
                        if (st > originalHeaderPosition) {
                            header.classList.remove('scrollUp');
                            header.classList.add('scrollDown');
                        } else {
                            header.classList.remove('scrollDown');
                            header.classList.remove('showSearch');
                            header.classList.add('scrollUp');
                        }
                    } else if (st > (lastScrollTop + navbarHeight) && st > navbarHeight) {
                        header.classList.remove('scrollUp');
                        header.classList.add('scrollDown');

                        // Handle ADO swiper stickyness
                        // if (swiperADO && w.matchMedia('(min-width: 820px)').matches) {
                        //     swiperADO.classList.remove('scroll');
                        // }
                        lastScrollTop = st;
                        if (navbarServices !== null
                        && navbarServices !== undefined) {
                            navbarServices.classList.add('servicesNavbar-hidingHeader');
                        }
                    } else if (st < lastScrollTop - navbarHeight) {
                    // if (st < navbarHeight * 2) {
                    //     header.classList.add('istant');
                    // } else {
                    //     header.classList.remove('istant');
                    // }
                        header.classList.remove('scrollDown');
                        header.classList.add('scrollUp');
                        // if (swiperADO && w.matchMedia('(min-width: 820px)').matches) {
                        //     swiperADO.classList.add('scroll');
                        // }

                        if (navbarServices !== null
                        && navbarServices !== undefined
                            && navbarServices.classList.contains('servicesNavbar-hidingHeader')) {
                            navbarServices.classList.remove('servicesNavbar-hidingHeader');
                        }
                        lastScrollTop = st;
                    }
                }
            });
        }
    }

    componentDidMount() {
        const { name } = this.getNavigationState();
        this.lastSeenMenu = name === MENU_TAB ? 0 : -1;
        if (!BrowserDatabase.getItem('auth_token_sso') || !BrowserDatabase.getItem(CUSTOMER)) {
            const customerFromCookie = Cookies.get(CUSTOMER);
            if (Cookies.get('auth_token_sso') && customerFromCookie) {
                const customerDecode = (JSON.parse(customerFromCookie));
                BrowserDatabase.setItem(Cookies.get('auth_token_sso'), 'auth_token', ONE_MONTH_IN_SECONDS);
                BrowserDatabase.setItem(customerDecode, CUSTOMER, ONE_MONTH_IN_SECONDS);
                BrowserDatabase.setItem(customerDecode.firstname, 'user_firstname', ONE_MONTH_IN_SECONDS);
                BrowserDatabase.setItem(customerDecode.lastname, 'user_lastname', ONE_MONTH_IN_SECONDS);
                Cookies.remove('auth_token_sso', { path: '', domain: '.mondoconv.it' });
                Cookies.remove('user_firstname', { path: '', domain: '.mondoconv.it' });
                Cookies.remove('user_lastname', { path: '', domain: '.mondoconv.it' });
                Cookies.remove('customer', { path: '', domain: '.mondoconv.it' });
                this.props.signInSso();
            }
        }

        const controlCookier = Cookies.get('controlCookie');
        if (controlCookier === 'false') {
            BrowserDatabase.deleteItem('auth_token');
            Cookies.remove('controlCookie', { path: '', domain: '.mondoconv.it' });
        }

        this.getFlag();
        this.getBFFlag();
        this.getFlagChair();
        this.getFlagSofas();
        this.getCollectionsCTA();

        super.componentDidMount();
    }

    async getCollectionsCTA() {
        const { getCollections } = this.props;
        const elems = {};
        await Promise.all(COLLECTIONS_ALLOWED.map(async (elem) => {
            const CTA = await this.getCollection(elem);
            if (CTA) {
                elems[elem] = CTA;
            }
        }));
        getCollections(elems);
    }

    async getCollection(elem) {
        const query = [CollezioniQuery.getQuery(elem)];
        this.setState({ isLoading: true });

        try {
            const response = await executeGet(prepareQuery(query), 'Categories', 0);
            return response.getCollezioni;
        } catch (e) {
            showNotification('error', __('Error fetching Category!'), e);
            return null;
        }
    }

    async getFlag() {
        const { getFlagConfigurator } = this.props;
        const flagConfigurator = await this.getFlagConfigurator();
        getFlagConfigurator(flagConfigurator);
    }

    async getFlagSofas() {
        const { getFlagConfiguratorSofas } = this.props;
        const flagConfigurator = await this.getFlagConfiguratorSofas();
        getFlagConfiguratorSofas(flagConfigurator);
    }

    async getFlagChair() {
        const { getFlagConfiguratorChair } = this.props;
        const flagConfigurator = await this.getFlagCollectionChair();
        getFlagConfiguratorChair(flagConfigurator);
    }

    async getBFFlag() {
        // const { getBFFlagConfigurator } = this.props;
        const flagBFConfigurator = await this.getBFFlagConfigurator();
        this.setState({ flagBFConfigurator });
        // getBFFlagConfigurator(flagBFConfigurator);
    }

    doLogout() {
        Cookies.remove('auth_token', { path: '', domain: '.mondoconv.it' });
        Cookies.remove('auth_token_sso', { path: '', domain: '.mondoconv.it' });
        Cookies.remove('user_firstname', { path: '', domain: '.mondoconv.it' });
        Cookies.remove('user_lastname', { path: '', domain: '.mondoconv.it' });
        Cookies.remove('customer', { path: '', domain: '.mondoconv.it' });
        this.props.logout();
    }

    onMyAccountButtonClick() {
        if (isSignedIn()) {
            history.push({ pathname: appendWithStoreCode('/my-account/dashboard') });
            return;
        }
        history.push({ pathname: appendWithStoreCode('/account/login') });
    }

    onMenuButtonClick() {
        const { navigationState: { name } } = this.props;

        // TODO: resolve issue when coming from CMS page

        if (name === MENU_TAB) { // if we already are in menu
            history.push(appendWithStoreCode('/menu'));
        } else if (this.lastSeenMenu <= 0) { // if we have not yet seen menu
            history.push(appendWithStoreCode('/menu'));
        } else { // otherwise go to last remembered category
            history.go(-this.lastSeenMenu);
        }

        this.lastSeenMenu = 0;
    }

    async getFlagConfigurator() {
        try {
            const API = '/rest/V1/use_configuratore/get/';
            const response = await axios.get(API);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async getFlagConfiguratorSofas() {
        try {
            const API = '/rest/V1/use_configuratore_divani/get/';
            const response = await axios.get(API);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async getBFFlagConfigurator() {
        try {
            const API = '/rest/V1/black_friday_design/get/';
            const response = await axios.get(API);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async getFlagCollectionChair() {
        try {
            const API = '/rest/V1/collezione_sedie/get/';
            const response = await axios.get(API);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        onMenuButtonClick: this.onMenuButtonClick.bind(this),
        doLogout: this.doLogout.bind(this)
    };

    render() {
        const { storeCode } = this.props;
        return (
            <Header
              { ...this.state }
              { ...this.containerProps() }
              { ...this.containerFunctions }
              storeCode={ storeCode }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));

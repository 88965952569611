/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import IfStore from 'Component/IfStore';
import Link from 'Component/Link';
import {
    ARMADI_ANTA_BATTENTE_CATEGORIES,
    ARMADI_ANTA_BATTENTE_REGEXP,
    ARMADI_ANTA_SCORREVOLE_CATEGORIES,
    ARMADI_ANTA_SCORREVOLE_REGEXP,
    BED_CATEGORIES,
    CAMERE_COMPLETE_MODERNE_CATEGORIES,
    CAMERE_COMPLETE_MODERNE_REGEXP,
    CAMERETTE_CATEGORIES,
    CAMERETTE_REGEXP,
    COMO_CASSETTIERE_COMODINI_CATEGORIES,
    COMO_CASSETTIERE_COMODINI_REGEXP,
    COMPOSIZIONI_FISSE_REGEXP,
    CUCINE_ELETTRODOMESTICI_REGEXP,
    DIVANI_2_3_POSTI_CATEGORIES,
    DIVANI_2_3_POSTI_REGEXP,
    DIVANI_LETTO_CATEGORIES,
    DIVANI_LETTO_REGEXP,
    DIVANI_POLTRONE_RELAX_CATEGORIES,
    DIVANI_POLTRONE_RELAX_REGEXP,
    ILLUMINAZIONE_CATEGORIES,
    ILLUMINAZIONE_REGEXP,
    KITCHEN_CATEGORIES,
    KITCHEN_FORNITURES_CATEGORIES,
    LETTI_REGEXP,
    N_OF_POSITIONS_BEFORE_BED,
    N_OF_POSITIONS_BEFORE_CM
} from 'Component/McMenuFullScreen/McMenuFullScreen.config';
// import Loader from 'Component/Loader';
import Overlay from 'Component/Overlay';
import ProductPrice from 'Component/ProductPrice';
import { McMenuNodeType } from 'Type/McMenuType';

import './McMenuFullScreen.style';

/** @namespace Pwa/Component/McMenuFullScreen/Component/McMenuFullScreenComponent */
export class McMenuFullScreenComponent extends PureComponent {
    static propTypes = {
        data: McMenuNodeType.isRequired,
        category: McMenuNodeType.isRequired,
        onCloseHandler: PropTypes.func.isRequired,
        onHideOverlay: PropTypes.func.isRequired,
        onVisibleOverlay: PropTypes.func.isRequired,
        onSubCategory: PropTypes.func.isRequired,
        subCategoryClick: PropTypes.func.isRequired,
        isSubCategoryClicked: PropTypes.bool,
        subCategoryClicked: PropTypes.string,
        flagConfigurator: PropTypes.string.isRequired,
        flagConfiguratorChair: PropTypes.string.isRequired,
        flagConfiguratorSofas: PropTypes.string.isRequired,
        rewriteUrls: PropTypes.func.isRequired
    };

    static defaultProps = {
        isSubCategoryClicked: false,
        subCategoryClicked: ''
    };

    state = {
        isCollectionSubCategoryClicked: false
    };

    componentDidMount() {
        const { category } = this.props;
        let i = 0;
        const maxPush = 13;
        while (i < category.mc_nav.length) {
            const items = [];
            for (let j = i; j < i + maxPush && j < category.mc_nav.length; j++) {
                items.push({
                    item_name: category.mc_nav[j]?.name,
                    affiliation: 'store online',
                    currency: 'EUR',
                    discount: Math.abs(category.mc_nav[j]?.special_price && category.mc_nav[j]?.list_price != 0
                        ? category.mc_nav[j]?.list_price - category.mc_nav[j]?.special_price : 0),
                    index: i,
                    item_brand: 'Mondo Convenienza',
                    item_category: category.name,
                    item_list_name: `Categoria ${category.name}`,
                    price: parseFloat(category.mc_nav[j]?.special_price
                        ? category.mc_nav[j]?.special_price : category.mc_nav[j]?.list_price),
                    quantity: 1
                });
            }
            window.dataLayer.push({
                event: 'view_item_list',
                ecommerce: {
                    items
                }
            });
            i += maxPush;
        }
    }

    componentDidUpdate() {
        const { category } = this.props;

        let i = 0;
        const maxPush = 13;
        while (i < category.mc_nav.length) {
            const items = [];
            for (let j = i; j < i + maxPush && j < category.mc_nav.length; j++) {
                items.push({
                    item_name: category.mc_nav[j]?.name,
                    affiliation: 'store online',
                    currency: 'EUR',
                    discount: Math.abs(category.mc_nav[j]?.special_price && category.mc_nav[j]?.list_price != 0
                        ? category.mc_nav[j]?.list_price - category.mc_nav[j]?.special_price : 0),
                    index: i,
                    item_brand: 'Mondo Convenienza',
                    item_category: category.name,
                    item_list_name: `Categoria ${category.name}`,
                    price: parseFloat(category.mc_nav[j]?.special_price
                        ? category.mc_nav[j]?.special_price : category.mc_nav[j]?.list_price),
                    quantity: 1
                });
            }
            window.dataLayer.push({
                event: 'view_item_list',
                ecommerce: {
                    items
                }
            });
            i += maxPush;
        }
    }

    renderMenuItem(item, customName = '') {
        const { onSubCategory, category: current_cagtegory } = this.props;
        const { path, name, url } = item;

        const isActive = (item.path === current_cagtegory.path);

        const caret = isActive
            ? <i className="fa fa-caret-left" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />
            : <i className="fa fa-caret-right" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />;

        const displayName = (customName.length > 0) ? customName : name;
        return (
            <li
              key={ path }
              block="McMenuFullScreen"
              elem="SubCategory"
              onMouseEnter={ () => onSubCategory(item) }
              onClick={ () => {
                  window.dataLayer.push({
                      event: 'click_mega_menu',
                      category: 'click_mega_menu',
                      action: name,
                      label: 'tutto'
                  });
              } }
            >
                <Link
                  to={ url }
                  block="McMenuFullScreen"
                  elem="Link"
                >
                    <span
                      block="McMenuFullScreen"
                      elem="Text"
                      mods={ { isActive } }
                    >
                        { displayName }
                    </span>
                </Link>
                { caret }
            </li>
        );
    }

    retriveAll(item) {
        return item.nodes;
    }

    retrieveTags(item, type = '') {
        const availableTags = [];
        let regex;
        if (type === 'kitchen') {
            regex = new RegExp(COMPOSIZIONI_FISSE_REGEXP);
        } else if (type === 'bed') {
            regex = new RegExp(LETTI_REGEXP);
        } else if (type === 'cameretta') {
            regex = new RegExp(CAMERETTE_REGEXP);
        } else if (type === 'cucine-elettrodomestici') {
            regex = new RegExp(CUCINE_ELETTRODOMESTICI_REGEXP);
        } else if (type === 'divani-letto') {
            regex = new RegExp(DIVANI_LETTO_REGEXP);
        } else if (type === 'divani-2-3-posti') {
            regex = new RegExp(DIVANI_2_3_POSTI_REGEXP);
        } else if (type === 'camere-complete-moderne') {
            regex = new RegExp(CAMERE_COMPLETE_MODERNE_REGEXP);
        } else if (type === 'armadi-ante-battente') {
            regex = new RegExp(ARMADI_ANTA_BATTENTE_REGEXP);
        } else if (type === 'armadi-ante-scorrevole') {
            regex = new RegExp(ARMADI_ANTA_SCORREVOLE_REGEXP);
        } else if (type === 'como-cassettiere-comodini') {
            regex = new RegExp(COMO_CASSETTIERE_COMODINI_REGEXP);
        } else if (type === 'illuminazione') {
            regex = new RegExp(ILLUMINAZIONE_REGEXP);
        } else if (type === 'divani-poltrone-relax') {
            regex = new RegExp(DIVANI_POLTRONE_RELAX_REGEXP);
        }

        if (regex !== undefined && item.hasOwnProperty('nodes') && Array.isArray(item.nodes) && item.nodes.length > 0) {
            item.nodes.forEach((ele) => {
                if (ele.hasOwnProperty('name') && (typeof ele.name === 'string')
                    && ele.name.length > 0 && regex.test(ele.name)) {
                    availableTags.push(ele);
                }
            });
        }

        return availableTags;
    }

    renderCamerettaTag(tag) {
        const measure = tag.name.trim();

        return (
            <div
              block="McMenuFullScreen"
              elem="SubMenu-Element"
            >
                <div
                  block="McMenuFullScreen"
                  elem="SubMenu-Element-Dash"
                />
                <Link
                  to={ tag.url }
                  block="McMenuFullScreen"
                  elem="SubMenu-Link"
                  onClick={ () => window.scrollTo(0, 0) }
                >
                    <span
                      block="McMenuFullScreen"
                      elem="SubMenu-Text"
                    >
                        { measure }
                    </span>
                </Link>
            </div>
        );
    }

    renderBedTag(tag) {
        const { subCategoryClicked } = this.props;
        const subcategoryName = tag.name.slice(0, tag.name.indexOf('-')).trim().toLowerCase();
        const measure = tag.name.slice((tag.name.indexOf('-') + N_OF_POSITIONS_BEFORE_BED)).trim();
        if (subCategoryClicked === subcategoryName) {
            return (
                <div
                  block="McMenuFullScreen"
                  elem="SubMenu-Element"
                >
                    <div
                      block="McMenuFullScreen"
                      elem="SubMenu-Element-Dash"
                    />
                    <Link
                      to={ tag.url }
                      block="McMenuFullScreen"
                      elem="SubMenu-Link"
                      onClick={ () => window.scrollTo(0, 0) }
                    >
                        <span
                          block="McMenuFullScreen"
                          elem="SubMenu-Text"
                        >
                            { measure }
                        </span>
                    </Link>
                </div>
            );
        }

        return null;
    }

    renderCollectionChairButton() {
        return (
            <li
              block="McMenuFullScreen"
              elem="SubCategory"
            >
                <a
                  className="collection chair"
                  href={ __('/collezione-sedie-interno-esterno-design/') }
                  role="button"
                >
                    { __('Sedie da interno ed esterno') }
                </a>
            </li>
        );
    }

    renderKitchenTagConfigurator() {
        return (
            <li
              block="McMenuFullScreen"
              elem="SubCategory"
            >
                <a
                  className="configuratoreCucine"
                  href={ __('/configuratore-cucine') }
                  role="button"
                  onClick={ () => {
                      window.dataLayer.push({
                          event: 'kitchen_configurator',
                          category: 'kitchen_configurator',
                          action: 'start',
                          start_point: 'menu',
                          category_name: 'cucine'
                      });
                  } }
                >
                    { __('CONFIGURA E ACQUISTA') }
                    { ' ' }
                    &nbsp;
                    <i className="fa fa-caret-right" aria-hidden="true" />
                </a>
            </li>
        );
    }

    renderSofasTagConfigurator() {
        return (
            <li
              block="McMenuFullScreen"
              elem="SubCategory"
            >
                <a
                  className="configuratoreCucine"
                  href={ __('/configuratore-divani') }
                  role="button"
                  onClick={ () => {
                      window.dataLayer.push({
                          event: 'sofa_configurator',
                          category: 'sofa_configurator',
                          action: 'start',
                          start_point: 'menu',
                          category_name: 'divani'
                      });
                  } }
                >
                    { __('CONFIGURA IL TUO DIVANO') }
                    { ' ' }
                    &nbsp;
                    <i className="fa fa-caret-right" aria-hidden="true" />
                </a>
            </li>
        );
    }

    renderKitchenTag(tag) {
        const measure = tag.name.slice((tag.name.indexOf('cm') - N_OF_POSITIONS_BEFORE_CM)).trim();

        return (
            <div
              block="McMenuFullScreen"
              elem="SubMenu-Element"
            >
                <div
                  block="McMenuFullScreen"
                  elem="SubMenu-Element-Dash"
                />
                <Link
                  to={ tag.url }
                  block="McMenuFullScreen"
                  elem="SubMenu-Link"
                  onClick={ () => window.scrollTo(0, 0) }
                >
                    <span
                      block="McMenuFullScreen"
                      elem="SubMenu-Text"
                    >
                        { measure }
                    </span>
                </Link>
            </div>
        );
    }

    renderGenericTag(tag, baseUrl = null) {
        const measure = tag.name.trim();

        return (
            <div
              block="McMenuFullScreen"
              elem="SubMenu-Element"
            >
                <div
                  block="McMenuFullScreen"
                  elem="SubMenu-Element-Dash"
                />
                <Link
                  to={ baseUrl ? `${baseUrl}${tag.url}` : tag.url }
                  block="McMenuFullScreen"
                  elem="SubMenu-Link"
                  onClick={ () => window.scrollTo(0, 0) }
                >
                    <span
                      block="McMenuFullScreen"
                      elem="SubMenu-Text"
                    >
                        { measure }
                    </span>
                </Link>
            </div>
        );
    }

    renderGenericCollectionTag(tag, baseUrl = null) {
        const measure = tag.name.trim();
        return (
            <div
              block="McMenuFullScreen"
              elem="SubMenu-Element"
            >
                <div
                  block="McMenuFullScreen"
                  elem="SubMenu-Element-Dash"
                />
                <a
                  href={ baseUrl ? `${baseUrl}${tag.url}` : tag.url }
                  block="McMenuFullScreen"
                  elem="SubMenu-Link"
                  onClick={ () => window.scrollTo(0, 0) }
                >
                    <span
                      block="McMenuFullScreen"
                      elem="SubMenu-Text"
                    >
                        { measure }
                    </span>
                </a>
            </div>
        );
    }

    redirectOnCategoryUrl(url) {
        window.location.href = url;
    }

    // OLD MENU
    renderSubOldMenuItem(item) {
        const {
            onSubCategory, category: current_cagtegory, isSubCategoryClicked, subCategoryClick, subCategoryClicked, data
        } = this.props;
        const { path, name, url } = item;

        const aux = path.split('/');
        const categoryId = aux[aux.length - 1];

        const isActive = (item.path === current_cagtegory.path);

        const caret = isActive
            ? <i className="fa fa-caret-left" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />
            : <i className="fa fa-caret-right" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />;

        /* these are the categories that can show a submenu */
        const isKitchen = KITCHEN_CATEGORIES.includes(categoryId);
        const isBed = BED_CATEGORIES.includes(categoryId);
        const isCameretta = CAMERETTE_CATEGORIES.includes(categoryId);
        const isKitchenfornitures = KITCHEN_FORNITURES_CATEGORIES.includes(categoryId);
        const isDivaniLetto = DIVANI_LETTO_CATEGORIES.includes(categoryId);
        const isDivani_2_3_Posti = DIVANI_2_3_POSTI_CATEGORIES.includes(categoryId);
        const isCamereCompleteModerne = CAMERE_COMPLETE_MODERNE_CATEGORIES.includes(categoryId);
        const isArmadiAnteBattente = ARMADI_ANTA_BATTENTE_CATEGORIES.includes(categoryId);
        const isArmadiAntaScorrevole = ARMADI_ANTA_SCORREVOLE_CATEGORIES.includes(categoryId);
        const isComoCassettiereComodini = COMO_CASSETTIERE_COMODINI_CATEGORIES.includes(categoryId);
        const isIlluminazione = ILLUMINAZIONE_CATEGORIES.includes(categoryId);
        const isDivaniPoltroneRelax = DIVANI_POLTRONE_RELAX_CATEGORIES.includes(categoryId);

        const isSubMenu = isKitchen || isBed || isCameretta || isKitchenfornitures
            || isDivaniLetto || isDivani_2_3_Posti || isCamereCompleteModerne || isArmadiAnteBattente
            || isArmadiAntaScorrevole || isComoCassettiereComodini || isIlluminazione || isDivaniPoltroneRelax;
        let type = '';
        if (isKitchen) {
            type = 'kitchen';
        } else if (isBed) {
            type = 'bed';
        } else if (isCameretta) {
            type = 'cameretta';
        } else if (isKitchenfornitures) {
            type = 'cucine-elettrodomestici';
        } else if (isDivaniLetto) {
            type = 'divani-letto';
        } else if (isDivani_2_3_Posti) {
            type = 'divani-2-3-posti';
        } else if (isCamereCompleteModerne) {
            type = 'camere-complete-moderne';
        } else if (isArmadiAnteBattente) {
            type = 'armadi-ante-battente';
        } else if (isArmadiAntaScorrevole) {
            type = 'armadi-ante-scorrevole';
        } else if (isComoCassettiereComodini) {
            type = 'como-cassettiere-comodini';
        } else if (isIlluminazione) {
            type = 'illuminazione';
        } else if (isDivaniPoltroneRelax) {
            type = 'divani-poltrone-relax';
        }

        if (isSubMenu) {
            const tags = this.retrieveTags(item, type);
            return (
                <>
                    <li
                      key={ path }
                      block="McMenuFullScreen"
                      elem="SubCategory"
                      onMouseEnter={ () => onSubCategory(item) }
                      onClick={ () => {
                          window.dataLayer.push({
                              event: 'click_mega_menu',
                              category: 'click_mega_menu',
                              action: data.name,
                              label: name
                          });
                      } }
                    >
                        <div
                          block="McMenuFullScreen"
                          elem="Link"
                        >
                            <div
                              block="McMenuFullScreen"
                              elem="Text"
                              mods={ { isActive } }
                              onClick={ tags.length > 0 ? subCategoryClick : () => {
                                  this.redirectOnCategoryUrl(url);
                              } }
                              role="button"
                            >
                                { name }
                            </div>
                        </div>
                        { caret }
                    </li>
                    { isSubCategoryClicked && name.toLowerCase() === subCategoryClicked
                        && tags.length > 0 && (
                            <div
                              block="McMenuFullScreen"
                              elem="SubMenu"
                            >
                                { (isKitchen || isCameretta) && (
                                    <div
                                      block="McMenuFullScreen"
                                      elem="SubMenu-Element"
                                    >
                                        <div
                                          block="McMenuFullScreen"
                                          elem="SubMenu-Element-Dash"
                                        />
                                        <Link
                                          to={ url }
                                          block="McMenuFullScreen"
                                          elem="SubMenu-Link"
                                          onClick={ () => window.scrollTo(0, 0) }
                                        >
                                            <span
                                              block="McMenuFullScreen"
                                              elem="SubMenu-Text"
                                              mods={ { isActive } }
                                            >
                                                Tutte
                                            </span>
                                        </Link>
                                    </div>
                                ) }
                                { (isBed || isKitchenfornitures || isDivaniLetto || isDivani_2_3_Posti
                                    || isCamereCompleteModerne || isArmadiAnteBattente
                                    || isArmadiAntaScorrevole || isComoCassettiereComodini || isIlluminazione || isDivaniPoltroneRelax) && (
                                        <div
                                          block="McMenuFullScreen"
                                          elem="SubMenu-Element"
                                        >
                                            <div
                                              block="McMenuFullScreen"
                                              elem="SubMenu-Element-Dash"
                                            />
                                            <Link
                                              to={ url }
                                              block="McMenuFullScreen"
                                              elem="SubMenu-Link"
                                              onClick={ () => window.scrollTo(0, 0) }
                                            >
                                                <span
                                                  block="McMenuFullScreen"
                                                  elem="SubMenu-Text"
                                                  mods={ { isActive } }
                                                >
                                                    { __('All') }
                                                </span>
                                            </Link>
                                        </div>
                                ) }
                                { isKitchen && tags.map((tag) => this.renderKitchenTag(tag)) }
                                { isBed && tags.map((tag) => this.renderBedTag(tag)) }
                                { isCameretta && tags.map((tag) => this.renderCamerettaTag(tag)) }
                                { (isKitchenfornitures || isDivani_2_3_Posti
                                    || isDivaniLetto || isCamereCompleteModerne || isArmadiAnteBattente
                                    || isArmadiAntaScorrevole
                                    || isComoCassettiereComodini
                                    || isIlluminazione
                                    || isDivaniPoltroneRelax)
                                    && tags.map((tag) => this.renderGenericTag(tag)) }
                            </div>
                    ) }
                </>
            );
        }

        return (
            <li
              key={ path }
              block="McMenuFullScreen"
              elem="SubCategory"
              onMouseEnter={ () => onSubCategory(item) }
              onClick={ () => {
                  window.dataLayer.push({
                      event: 'click_mega_menu',
                      category: 'click_mega_menu',
                      action: data.name,
                      label: name
                  });
              } }
            >
                <Link
                  to={ url }
                  block="McMenuFullScreen"
                  elem="Link"
                >
                    <span
                      block="McMenuFullScreen"
                      elem="Text"
                      mods={ { isActive } }
                    >
                        { name }
                    </span>
                </Link>
                { caret }
            </li>
        );
    }

    renderSubMenuItem(item) {
        const {
            onSubCategory, category: current_cagtegory, data, rewriteUrls
        } = this.props;
        const { path, name, url } = item;

        if (name.toLowerCase() === 'collezioni') {
            return this.renderNewButtonCollection(data.name.toLowerCase());
        }

        const aux = path.split('/');
        const categoryId = aux[aux.length - 1];

        const isActive = (item.path === current_cagtegory.path);

        const caret = isActive
            ? <i className="fa fa-caret-left" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />
            : <i className="fa fa-caret-right" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />;

        /* Categories that can show a submenu */
        const isKitchen = KITCHEN_CATEGORIES.includes(categoryId);
        const isBed = BED_CATEGORIES.includes(categoryId);
        const isCameretta = CAMERETTE_CATEGORIES.includes(categoryId);
        const isKitchenfornitures = KITCHEN_FORNITURES_CATEGORIES.includes(categoryId);
        const isDivaniLetto = DIVANI_LETTO_CATEGORIES.includes(categoryId);
        const isDivani_2_3_Posti = DIVANI_2_3_POSTI_CATEGORIES.includes(categoryId);
        const isCamereCompleteModerne = CAMERE_COMPLETE_MODERNE_CATEGORIES.includes(categoryId);
        const isArmadiAnteBattente = ARMADI_ANTA_BATTENTE_CATEGORIES.includes(categoryId);
        const isArmadiAntaScorrevole = ARMADI_ANTA_SCORREVOLE_CATEGORIES.includes(categoryId);
        const isComoCassettiereComodini = COMO_CASSETTIERE_COMODINI_CATEGORIES.includes(categoryId);
        const isIlluminazione = ILLUMINAZIONE_CATEGORIES.includes(categoryId);
        const isDivaniPoltroneRelax = DIVANI_POLTRONE_RELAX_CATEGORIES.includes(categoryId);

        const isSubMenu = isKitchen || isBed || isCameretta || isKitchenfornitures
            || isDivaniLetto || isDivani_2_3_Posti || isCamereCompleteModerne || isArmadiAnteBattente
            || isArmadiAntaScorrevole || isComoCassettiereComodini || isIlluminazione || isDivaniPoltroneRelax;
        let type = '';
        if (isKitchen) {
            type = 'kitchen';
        } else if (isBed) {
            type = 'bed';
        } else if (isCameretta) {
            type = 'cameretta';
        } else if (isKitchenfornitures) {
            type = 'cucine-elettrodomestici';
        } else if (isDivaniLetto) {
            type = 'divani-letto';
        } else if (isDivani_2_3_Posti) {
            type = 'divani-2-3-posti';
        } else if (isCamereCompleteModerne) {
            type = 'camere-complete-moderne';
        } else if (isArmadiAnteBattente) {
            type = 'armadi-ante-battente';
        } else if (isArmadiAntaScorrevole) {
            type = 'armadi-ante-scorrevole';
        } else if (isComoCassettiereComodini) {
            type = 'como-cassettiere-comodini';
        } else if (isIlluminazione) {
            type = 'illuminazione';
        } else if (isDivaniPoltroneRelax) {
            type = 'divani-poltrone-relax';
        }

        const getTags = () => {
            if (isSubMenu) {
                return this.retrieveTags(item, type);
            }

            if (data.name.toLowerCase() === 'promozioni') {
                return this.retriveAll(item);
            }

            return [];
        };

        const tags = getTags();

        return (
            <li>
                <div
                  key={ path }
                  block="McMenuFullScreen"
                  elem="SubCategory"
                  onMouseEnter={ () => onSubCategory(item) }
                  onClick={ () => {
                      window.dataLayer.push({
                          event: 'click_mega_menu',
                          category: 'click_mega_menu',
                          action: data.name,
                          label: name
                      });
                  } }
                >
                    <div
                      block="McMenuFullScreen"
                      elem="Link"
                      onClick={ () => this.redirectOnCategoryUrl(rewriteUrls(url)) }
                      role="button"
                    >
                        <div
                          block="McMenuFullScreen"
                          elem="Text"
                          mods={ { isActive } }
                        >
                            { name }
                        </div>
                    </div>
                    { caret }
                </div>
                { (isSubMenu || (data.name.toLowerCase() === 'promozioni' && tags.length > 0)) && (
                    <div
                      block="McMenuFullScreen"
                      elem="SubMenu"
                    >
                        { tags.length > 0 && tags.map((tag) => this.renderGenericTag(tag)) }
                    </div>
                ) }
            </li>
        );
    }

    renderActionBar() {
        const { onCloseHandler, data: { path } } = this.props;
        return (
            <div block="McMenuFullScreen" elem="Actions">
                <div
                  key={ path }
                  block="McMenuFullScreen"
                  aria-label="Close Button"
                  elem="CloseBtn"
                  role="button"
                  tabIndex="0"
                  onClick={ onCloseHandler }
                />
            </div>
        );
    }

    renderPrice(item) {
        const finalPrice = item.special_price || item.list_price;
        const { miglior_prezzo } = item;

        const price = {
            minimum_price: {
                discount: {
                    // eslint-disable-next-line no-magic-numbers
                    percent_off: 100 - 100 * (finalPrice / item.list_price)
                },
                final_price: {
                    value: finalPrice,
                    currency: 'EUR'
                },
                final_price_excl_tax: {
                    value: finalPrice,
                    currency: 'EUR'
                },
                regular_price: {
                    value: item.list_price,
                    currency: 'EUR'
                },
                regular_price_excl_tax: {
                    value: item.list_price,
                    currency: 'EUR'
                }
            },
            miglior_prezzo
        };

        return <ProductPrice price={ price } promoPosition="left_menu" />;
    }

    renderMenuIcons() {
        const { category } = this.props;
        if (!category || !category.mc_nav) {
            return null;
        }

        // Task #260: Ricavo l'url del dettaglio prodotto solo sulla categorie di seguito e sottocategorie
        const isDivani = category.url.includes(__('divani'));
        const isLetti = category.url.includes(__('letti2'));
        const isBagni = category.url.includes(__('bagni'));
        const isSedie = category.url.includes(__('sedie'));
        const isTavoli = category.url.includes(__('tavoli'));
        const isSoggiorni = category.url.includes(__('soggiorni'));
        const isMaterassi = category.url.includes(__('materassi'));
        const isCamere = category.url.includes(__('camere'));
        const isCucine = category.url.includes(__('cucine'));
        const isCamerette = category.url.includes(__('camerette'));

        return (
            <div block="McMenuFullScreen" elem="List">
                <div className="CmsBlock-Wrapper CmsBlock-Wrapper_type_Menu">
                    { category.mc_nav.map((item, index) => (
                        <div className="mc-menu-category col-xs-4 col-4" key={ JSON.stringify({ url: item.url, index }) }>
                            <Link
                              to={ (isDivani || isLetti || isBagni || isSedie || isTavoli || isSoggiorni
                                    || isMaterassi || isCamere || isCucine || isCamerette)
                                    && item.product_url ? item.product_url : item.url || '' }
                              className="mc-menu-product__link"
                              onClick={ () => window.dataLayer.push({
                                  event: 'select_item',
                                  action_type: 'listing',
                                  ecommerce: {
                                      items: [
                                          {
                                              item_name: item?.name,
                                              affiliation: 'store online',
                                              currency: 'EUR',
                                              discount: item?.special_price
                                                  ? item?.list_price - item?.special_price : 0,
                                              index,
                                              item_brand: 'Mondo Convenienza',
                                              item_category: category.name,
                                              item_list_name: `Categoria ${category.name}`,
                                              price: item?.special_price
                                                  ? item?.special_price : item?.list_price,
                                              quantity: 1
                                          }
                                      ]
                                  }
                              }) }
                            >
                                <div
                                  className="
                                    Image
                                    Image_ratio_square
                                    Image_imageStatus_1
                                    Image_hasSrc
                                    mc-menu-product__image
                                  "
                                >
                                    <img
                                      className="mc-menu-product__image"
                                      src={ item.photo }
                                      alt={ item.name }
                                    />
                                </div>

                                <div className="mc-menu-product__bottom">
                                    <span className="mc-menu-product__price">
                                        { item.list_price && this.renderPrice(item) }
                                    </span>
                                    <span className="mc-menu-product__name">{ item.name }</span>
                                </div>
                            </Link>
                        </div>
                    )) }
                </div>
            </div>
        );
    }

    renderCategoryName() {
        const { data, data: { name, url } } = this.props;

        if (this.isNewMenu(data)) {
            return (
                <Link to={ url } block="McMenuFullScreen" elem="Category-Name">
                    { name }
                </Link>
            );
        }

        return (
            // eslint-disable-next-line react/style-prop-object
            <div block="McMenuFullScreen" elem="Category-Name">
                { name }
            </div>
        );
    }

    renderButtonCollection(collection, datas) {
        const url = `/collezioni/${datas}`;
        const { isCollectionSubCategoryClicked } = this.state;
        const isActive = isCollectionSubCategoryClicked;
        const caret = isActive
            ? <i className="fa fa-caret-left" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />
            : <i className="fa fa-caret-right" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />;

        const shouldRenderCollection = collection && collection.length > 0 && collection.some((tag) => tag.includeInMenu);

        const { data } = this.props;

        if (this.isNewMenu(data)) {
            return this.renderNewButtonCollection(datas);
        }

        return shouldRenderCollection && (
            <>
                <li block="McMenuFullScreen" elem="SubCategory">
                    { datas === 'divani' && (
                        <>
                            <div block="McMenuFullScreen" elem="Link">
                                <div
                                  block="McMenuFullScreen"
                                  elem="Text"
                                  mods={ { isActive } }
                                  onClick={ () => {
                                      this.setState((prevState) => ({
                                          isCollectionSubCategoryClicked: !prevState.isCollectionSubCategoryClicked
                                      }));
                                  } }
                                  role="button"
                                >
                                    Collezioni
                                </div>
                            </div>
                            { caret }
                        </>
                    ) }
                    { datas !== 'divani' && (
                        <div
                          className="collection"
                          role="button"
                          onClick={ () => {
                              this.setState((prevState) => ({ isCollectionSubCategoryClicked: !prevState.isCollectionSubCategoryClicked }));
                          } }
                        >
                            { __('Collezioni') }
                        </div>
                    ) }
                </li>
                { isCollectionSubCategoryClicked && collection.length > 0 && (
                    <div
                      block="McMenuFullScreen"
                      elem="SubMenu"
                    >
                        <div
                          block="McMenuFullScreen"
                          elem="SubMenu-Element"
                        >
                            <div
                              block="McMenuFullScreen"
                              elem="SubMenu-Element-Dash"
                            />
                            <a
                              href={ url }
                              block="McMenuFullScreen"
                              elem="SubMenu-Link"
                              onClick={ () => window.scrollTo(0, 0) }
                            >
                                <span
                                  block="McMenuFullScreen"
                                  elem="SubMenu-Text"
                                >
                                    Tutte
                                </span>
                            </a>
                        </div>
                        { collection.filter((tag) => tag.includeInMenu).map((tag) => this.renderGenericCollectionTag(tag, url)) }
                    </div>
                ) }
            </>
        );
    }

    renderNewButtonCollection(data) {
        const url = `/collezioni/${data}`;

        return (
            <li block="McMenuFullScreen" elem="SubCategory">
                <a
                  block="McMenuFullScreen"
                  elem="Link"
                  className="collection"
                  href={ url }
                >
                        { __('Collezione') }
                        { ' ' }
                        { data }
                        <i className="fa fa-caret-right" block="McMenuFullScreen" elem="Arrow" aria-hidden="true" />
                </a>
            </li>
        );
    }

    renderOneColumn(data) {
        return data.name.toLowerCase() === 'promozioni';
    }

    isNewMenu(data) {
        return ['bagni', 'complementi', 'promozioni'].includes(data.name.toLowerCase());
    }

    renderMenuSubMenu() {
        const {
            // eslint-disable-next-line react/prop-types
            data, flagConfigurator, collections, flagConfiguratorChair, flagConfiguratorSofas
        } = this.props;

        // CONFIGURATORE CUCINE
        const isKitchen = data.name.toLowerCase() === 'cucine' && (flagConfigurator === '1');
        const isChair = data.name.toLowerCase() === 'tavoli e sedie' && flagConfiguratorChair === '1';
        const isDivani = data.name.toLowerCase() === 'divani' && flagConfiguratorSofas === '1';

        const isCollections = data.name.toLowerCase() in collections;

        // eslint-disable-next-line no-magic-numbers
        // const columns = this.renderOneColumn(data) ? 1 : parseInt((data.nodes.length + 1) / 5 + 1, 10);
        const columns = this.renderOneColumn(data) ? 1 : 2;

        return (
            <div block="McMenuFullScreen" elem="Menu">
                { this.renderCategoryName() }
                <ul style={ { columns: this.isNewMenu(data) ? columns : '' } }>
                    { !this.isNewMenu(data) && this.renderMenuItem(data, __('All')) }
                    { this.isNewMenu(data)
                        ? data.nodes.map((item) => this.renderSubMenuItem(item))
                        : data.nodes.map((item) => this.renderSubOldMenuItem(item)) }
                    { isKitchen && this.renderKitchenTagConfigurator() }
                    { isChair && this.renderCollectionChairButton() }
                    <IfStore storeCode="default">
                        { isCollections && !this.isNewMenu(data) && this.renderButtonCollection(collections[data.name.toLowerCase()], data.name.toLowerCase().replaceAll(' ', '-')) }
                    </IfStore>
                    { isDivani && this.renderSofasTagConfigurator() }
                </ul>
            </div>
        );
    }

    render() {
        const {
            onVisibleOverlay, onHideOverlay, data: { path }, data
        } = this.props;

        return (
            <Overlay
              id={ path }
              mix={ {
                  block: 'McMenuFullScreen',
                  elem: 'Container',
                  mods: { newMenu: this.isNewMenu(data), [data.name.toLowerCase()]: true }
              } }
              onHide={ () => onHideOverlay() }
              onVisible={ () => onVisibleOverlay() }
              fullBlack={ this.isNewMenu(data) }
            >
                <div block="McMenuFullScreen" elem="Wrapper">
                    <div block="McMenuFullScreen" elem="Content">
                        { this.renderMenuSubMenu() }
                        { this.renderMenuIcons() }
                    </div>
                    { this.renderActionBar() }
                </div>
            </Overlay>
        );
    }
}

export default McMenuFullScreenComponent;
